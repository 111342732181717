import React, { FC, memo, ReactNode, useEffect, useState } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';
import { useRouter } from 'next/router';

interface IProps {
    modalName: string
    className: string
    modalSize?: 'sm' | 'lg' | 'xl'
    closeModal: () => void
    show: boolean
    children: ReactNode
    Title?: ReactNode | null
}

const CustomModal: FC<IProps> = ({ modalName, className, modalSize, closeModal, show, children, Title }) => {
  const [ showModal, setShowModal ] = useState<boolean>( show || false );
  const router = useRouter();
  const hasHash = router.asPath.includes( '#' )

  const openHandler = () => {
    if ( !hasHash ) {
      router.push({
        hash: modalName + ''
      }, undefined, { scroll: false })
    }
    setShowModal( true );
  }

  const closeHandler = () => {
    closeModal();
    setShowModal( false );
    if ( showModal ) {
      router.back()
    }
  }
  // const clickOutSideHandler = () => {
  //   closeModal();
  //   setShowModal( false );
  // }


  // useEffect( () => {
  //   if ( show ) openHandler();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [show])

  // useEffect( () => {
  //   if ( !hasHash && showModal ) clickOutSideHandler();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [hasHash]);

  return (
    <Modal
      className={className}
      size={modalSize}
      show={show}
      onHide={closeHandler}
      animation={true}
      // onOverlayClick={clickOutSideHandler}
    >
      <Modal.Header>
        <CloseButton onClick={closeHandler}/>
        {Title}
      </Modal.Header>
      {children}
    </Modal>
  )
}

export default memo( CustomModal );
