import Checkers from "../../../../helpers/class/Checkers";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from 'swiper';
import Image from "next/image";
import React, {FC, ReactComponentElement, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft as fasAngleLeft, faAngleRight as fasAngleRight} from "@fortawesome/pro-solid-svg-icons";
import {AnyComponent} from "styled-components/native/dist/types";
import { useEffect } from 'react';
import {useRouter} from "next/router";
import SwiperColorClasses from "../../../plugins/swiperUtils";

import 'swiper/css';
import GalleryItem from "../../property/partials/gallery/GalleryItem";
import CustomModal from "../../../CustomModal";
import ModalPropertyImages from "../../property/partials/modals/ModalPropertyImages";
import {useWindowSize} from "../../../../helpers/MethodHelper";
import Motto from "../../../jumbotron/Motto";

SwiperCore.use([ Autoplay, Navigation, Pagination, Lazy ]);

interface SwipperProps {
    images?: Array<any>|null
    imagesToShow?: number
    imageKey?: string|null
    galleryHeight?: string|null
    paginationBulletsEnabled?: boolean
    paginationBullets?: boolean
    dynamicMainBullets?: number
    imageSlider?: boolean
    htmlSlides?: Array<ReactComponentElement<AnyComponent>>|null
    slidesPerView?: number
    slidesPerViewLg?: number
    slidesPerViewMd?: number
    slidesPerViewSm?: number
    slidesPerViewXs?: number
    wrapperClassName?: string
    enableAutoplay?: boolean
    designMode?: number
    spaceBetween?: number
    customNavigationArrows?:  boolean
    fadeBottom?: boolean
    enableFancyBox?: boolean
    enableAlbumRightModal?: boolean
    centeredSlides?: boolean
    enableLoop?: boolean
    jumbotronOverlayOpacity?: number
    motto?: string
    firstSlideTitle?: string
    firstSlideSubtitle?: string
    logoDecoration?: string
    logoDecorationOriginWidth?: string
    logoDecorationOriginHeight?: string
    jumbotronTextAlign?: string
    jumbotronTextColor?: string
    sliderTitleShadow?: string
    sliderTitleBold?: string
    pageTitle?: string
}

const SwipperWrapper: FC<SwipperProps> = (
    {
        images,
        imagesToShow= null,
        imageKey= 'image',
        galleryHeight = "auto",
        paginationBulletsEnabled = true,
        paginationBullets = true,
        dynamicMainBullets = 6,
        imageSlider = true,
        htmlSlides,
        slidesPerView= 1,
        slidesPerViewLg = 1,
        slidesPerViewMd = 1,
        slidesPerViewSm = 1,
        slidesPerViewXs = 1,
        wrapperClassName= 'Properties__gallery m-t-20',
        enableAutoplay= false,
        designMode = 1,
        spaceBetween = 20,
        customNavigationArrows = true,
        fadeBottom = false,
        enableFancyBox = true,
        enableAlbumRightModal = true,
        centeredSlides = true,
        enableLoop = false,
        jumbotronOverlayOpacity = 0,
        motto = null,
        firstSlideTitle =  null,
        firstSlideSubtitle =  null,
        logoDecoration = null,
        logoDecorationOriginWidth = null,
        logoDecorationOriginHeight = null,
        jumbotronTextAlign = null,
        jumbotronTextColor = null,
        sliderTitleShadow = null,
        sliderTitleBold = null,
        pageTitle = null
    }
) => {


    const [swiperId, setSwiperId] = useState<string>(Math.random().toString(36).slice(3))

    const size = useWindowSize()

    const visibleSlides = () => {
        if (size['width'] > 1200)
            return slidesPerView
        if (size['width'] > 991)
            return slidesPerViewLg
        if (size['width'] > 768)
            return slidesPerViewMd
        if (size['width'] > 576)
            return slidesPerViewSm
        if (size['width'] <= 576)
            return slidesPerViewXs

        return slidesPerView;
    }


    const getImagesForSwiper = () => {
        let images_for_swiper = []
        if (imagesToShow != null) {
            images && images.map((item, index) => {
                if (index < imagesToShow) {
                    images_for_swiper.push(item)
                }
            })
        } else {
            images && images.map((item, index) => {
                images_for_swiper.push(item)
            })
        }

        return images_for_swiper
    }

    const imagesForSwiper = getImagesForSwiper()

    const myLoader = ({src}) => {
        return `${src}?q=55`
    }

    const autoplay = {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    };

    const fancyBoxName = "gallery_" + (Math.random() + 1).toString(36).substring(10);

    const navigationHandler = () => {
        if (imageSlider && images.length < 2) {
            return false
        }

        if (customNavigationArrows) {
            return (Checkers.isValidArray(images) && images.length > 1) || (htmlSlides != undefined && htmlSlides.length > 0) ? {
                enabled: true,
                nextEl: '.swiper-custom-next-'+swiperId,
                prevEl: '.swiper-custom-prev-'+swiperId
            } : false
        } else {
            return true
        }
    }

    const navigation = useState(navigationHandler())

    const swiper_options = {
        mode: 'white'
    };

    const router = useRouter();
    useEffect(() => {
        if (router.route.includes('/blog')) {
            if (window !== undefined) {
                swiper_options.mode = designMode == 1 ? 'white' : 'dark';
            }
            new SwiperColorClasses(swiper_options).swiperOptions();
        }
    });

    const [show, setShow] = useState(false);
    const [modalState, setModalState] = useState("close");

    const handleShow = (state) => {
        setShow(true);
        setModalState('property_featured_photos_modal_slider');

        return true
    }

    const handleHide = () => {
        setShow(false);
        setModalState("close");
    }

    return (
        <>
            <div className={wrapperClassName + " properties"} style={{height: galleryHeight}}>
                <Swiper
                    id={swiperId}
                    key={swiperId}
                    spaceBetween={spaceBetween}
                    speed={1000}
                    navigation={navigationHandler()}
                    noSwiping={Checkers.isValidBoolean(navigation)}
                    pagination={{
                        enabled: paginationBulletsEnabled,
                        dynamicBullets: paginationBullets,
                        dynamicMainBullets: dynamicMainBullets,
                        clickable: true
                    }}
                    loop={enableLoop}
                    style={{borderRadius: "0px"}}
                    slidesPerView={visibleSlides()}
                    autoplay={enableAutoplay ? autoplay : false}
                    centeredSlides={centeredSlides}
                >
                    <>
                        {
                            customNavigationArrows && ((images != undefined && images.length > 1) || (htmlSlides != undefined && htmlSlides.length > 1)) ?
                                <>
                                    <div
                                        className={'swiper-button-prev swiper-custom-prev-'+swiperId+' swiper-custom-prev\ ' + `${Checkers.isValidBoolean(navigation) ? 'hidden' : ''}`}>
                                        <FontAwesomeIcon icon={fasAngleLeft} className="text-master fs-16"/>
                                    </div>
                                    <div
                                        className={'swiper-button-next swiper-custom-next-'+swiperId+' swiper-custom-next\ ' + `${Checkers.isValidBoolean(navigation) ? 'hidden' : ''}`}>
                                        <FontAwesomeIcon icon={fasAngleRight} className="text-master fs-16"/>
                                    </div>
                                </>
                                :
                                <></>
                        }
                    </>
                    {
                        imageSlider ?
                            imagesForSwiper.map((slide, key) => {
                                return (
                                    Checkers.isValidString(slide[imageKey]) &&
                                    <SwiperSlide key={key} onClick={handleShow}>
                                        {
                                            !enableFancyBox ?
                                                <>
                                                    <Image
                                                        className={'w-100 ' + galleryHeight}
                                                        style={{height: 'auto'}}
                                                        loader={myLoader}
                                                        priority={key == 0}
                                                        layout={'fill'}
                                                        sizes={'55vw'}
                                                        quality={'55'}
                                                        src={slide[imageKey]}
                                                        // alt={'Property Slide-' + props.property_id + '-' + key}
                                                        objectFit={'cover'}
                                                        placeholder={'blur'}
                                                        alt={Checkers.isValidString(pageTitle) ? pageTitle : 'carousel image'}
                                                        blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                                                    />
                                                    <>
                                                        {
                                                            jumbotronOverlayOpacity > 0 &&
                                                            <div className="bg-overlay z-index-1" style={{ backgroundColor: 'rgb(0, 0, 0)', opacity: jumbotronOverlayOpacity }}/>
                                                        }
                                                    </>
                                                    <>
                                                        {
                                                            key == 0 ?
                                                                motto != null &&
                                                                <>
                                                                    <Motto
                                                                        // isDesktop={props.isDesktop}
                                                                        motto={motto}
                                                                        logoDecoration={logoDecoration}
                                                                        logoDecorationOriginWidth={logoDecorationOriginWidth}
                                                                        logoDecorationOriginHeight={logoDecorationOriginHeight}
                                                                        jumbotronTextAlign={jumbotronTextAlign}
                                                                        jumbotronTextColor={jumbotronTextColor}
                                                                        sliderTitleShadow={sliderTitleShadow}
                                                                        sliderTitleBold={sliderTitleBold}
                                                                        homeTitle={firstSlideTitle}
                                                                        homeSubtitle={firstSlideSubtitle}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        slide.caption != null && slide.caption.length > 0 ?
                                                                            <div
                                                                                className="bottom-right p-r-70 p-b-50 sm-p-r-20 sm-p-b-20 hidden-xs"
                                                                                style={{zIndex: 999}}>
                                                                                <p className="text-white text-right pull-right m-r-10">
                                                                                    {slide.caption}
                                                                                </p>
                                                                            </div>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </>
                                                        }
                                                    </>
                                                </>
                                                :
                                                <GalleryItem
                                                    fancybox={fancyBoxName}
                                                    key={slide.image_id != undefined ? slide.image_id.toString() : key}
                                                    className={' w-100 h-100 '}
                                                    imageSize={'55vw'}
                                                    imageQuality={55}
                                                    image={slide[imageKey]}
                                                    imageThumb={slide[imageKey]}
                                                    enableHoverZoom={false}
                                                    galleryClassName={' col-12 '}
                                                    arrClassName={' ar-2-1 h-100 '}
                                                />

                                        }
                                        {
                                            fadeBottom ?
                                                <>
                                                    <div className="bg-overlay z-index-1 position-absolute start-0"
                                                         style={{
                                                             background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0) , rgba(0, 0, 0, 0.3))',
                                                             height: '35%',
                                                             top: 'unset',
                                                             bottom: '15%'
                                                         }}/>
                                                    <div className="bg-overlay z-index-1 position-absolute start-0"
                                                         style={{
                                                             background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.3) , rgba(0, 0, 0, 0.8))',
                                                             height: '15%',
                                                             top: 'unset',
                                                             bottom: 0
                                                         }}/>
                                                </>
                                                :
                                                <></>
                                        }
                                    </SwiperSlide>
                                )
                            })
                            :
                            htmlSlides.map((slide) => {
                                return <>{slide}</>
                            })
                    }
                </Swiper>
            </div>
            <>
                {
                    enableAlbumRightModal && images && images.length > 2 &&
                    <>
                        <CustomModal
                            modalName={'property_featured_photos_modal_slider'}
                            className="slide_right"
                            modalSize="lg"
                            closeModal={() => {
                                handleHide()
                            }}
                            show={modalState === 'property_featured_photos_modal_slider'}
                        >
                            <ModalPropertyImages all_images={images} handleHide={handleHide}/>
                        </CustomModal>
                    </>
                }
            </>
        </>
    )
}

export default SwipperWrapper