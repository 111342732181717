import React from 'react';
import Image from 'next/image';

import BookingSearchBar from '../templates/index/BookingSearchBar';
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../helpers/MethodHelper";
import Checkers from "../../helpers/class/Checkers";

export default function Motto ( props ) {

  const isMobile = useSelector(state => state['appSettings']['isMobile'])
  const isTablet = useSelector(state => state['appSettings']['isTablet'])
  const isDesktop = useSelector(state => state['appSettings']['isDesktop'])

  let jumbotronTextAlign = null;
  let jumbotronTextColor = null;

  //---------------- TEXT ALIGNMENT ----------------
  if (props.jumbotronTextAlign == 0)
    jumbotronTextAlign = 'align-items-start text-left justify-content-start';
  else if (props.jumbotronTextAlign == 1)
    jumbotronTextAlign = 'align-items-center text-center justify-content-center';
  else if (props.jumbotronTextAlign == 2)
    jumbotronTextAlign = 'align-items-end text-right justify-content-center';
  else
    jumbotronTextAlign = 'align-items-start';

  //---------------- TEXT COLOR ----------------
  if (props.jumbotronTextColor)
    jumbotronTextColor = 'text-white';
  else
    jumbotronTextColor = 'text-black';

  //---------------- TEXT BOLD ----------------
  if (props.sliderTitleBold)
    jumbotronTextColor += '\ bold';

  const homepageMottoOverride = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_motto_overide')
  );

  const homeTitleMotto = () => {
    return (
        Checkers.isValidString(homepageMottoOverride) ?
            <span dangerouslySetInnerHTML={{__html: homepageMottoOverride}}/>
            :
            props.homeTitle
    )
  }

  return (
      <div className="motto inner full-height position-absolute top-0 h-100 w-100" style={{zIndex: 99}}>
        <div className="container-xs-height full-height">
          <div className="col-xs-height col-middle text-left">
            <div className="row justify-content-center w-100 no-margin">
              <div className="col-12 col-md-8 p-0">
                <div className="container no-gutter">
                  {
                    props.hasOwnProperty('hideMotto') ?
                        ''
                        :
                        <div className="row no-gutter">
                          <div
                              className={'col-sm-12 col-sm-offset-0 d-flex flex-column justify-content-center\ ' + jumbotronTextAlign + '\ ' + jumbotronTextColor + '\ z-index-3'}>
                            {
                              props.motto !== undefined ?
                                  <>
                                    {
                                      props.isDesktop && props.logoDecoration != null ?
                                          <div className={"d-flex col-8 h-auto logo-decoration-div hidden-xs " + jumbotronTextAlign}>
                                            <Image
                                                loading={'eager'}
                                                width={props.logoDecorationOriginWidth}
                                                height={props.logoDecorationOriginHeight}
                                                objectFit={'contain'}
                                                className="image logo-decoration"
                                                alt={'logo_decoration'}
                                                src={props.logoDecoration}
                                                placeholder={'blur'}
                                                blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                                            />
                                          </div>
                                          : ''
                                    }
                                    {
                                      homeTitleMotto() != null || props.homeSubtitle != null ?

                                          homeTitleMotto() != null && props.homeSubtitle == null ?
                                              <>
                                                <h1
                                                    className={'font-montserrat m-b-0 p-b-20 hidden-xs hidden-sm hidden-md\ ' + jumbotronTextColor}
                                                    style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{homeTitleMotto()}</h1>
                                                <h2
                                                    className={'font-montserrat visible-md\ ' + jumbotronTextColor}
                                                    style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{homeTitleMotto()}</h2>
                                                <p
                                                    className={'h3 font-montserrat  hidden-md visible-xs visible-sm\ ' + jumbotronTextColor}
                                                    style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{homeTitleMotto()}</p>
                                              </>
                                              : props.homeSubtitle != null && homeTitleMotto() == null ?
                                                  <>
                                                    <p
                                                        className={'h3 font-montserrat m-t-0 m-b-40 hidden-xs hidden-sm hidden-md\ ' + jumbotronTextColor}
                                                        style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{props.homeSubtitle}</p>
                                                    <h4
                                                        className={'font-montserrat visible-md\ ' + jumbotronTextColor}
                                                        style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{props.homeSubtitle}</h4>
                                                    <p
                                                        className={'h5 font-montserrat hidden-md visible-xs visible-sm\ ' + jumbotronTextColor}
                                                        style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{props.homeSubtitle}</p>
                                                  </>
                                                  :
                                                  <>
                                                    <h1
                                                        className={'font-montserrat m-b-0 p-b-20 hidden-xs hidden-sm hidden-md\ ' + jumbotronTextColor}
                                                        style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{homeTitleMotto()}</h1>
                                                    <h2
                                                        className={'font-montserrat visible-md\ ' + jumbotronTextColor}
                                                        style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{homeTitleMotto()}</h2>
                                                    <p
                                                        className={'h3 font-montserrat  hidden-md visible-xs visible-sm\ ' + jumbotronTextColor}
                                                        style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{homeTitleMotto()}</p>
                                                    <p
                                                        className={'h3 font-montserrat m-t-0 m-b-40 hidden-xs hidden-sm hidden-md\ ' + jumbotronTextColor}
                                                        style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{props.homeSubtitle}</p>
                                                    <h4
                                                        className={'font-montserrat visible-md\ ' + jumbotronTextColor}
                                                        style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{props.homeSubtitle}</h4>
                                                    <p
                                                        className={'h5 font-montserrat hidden-md visible-xs visible-sm\ ' + jumbotronTextColor}
                                                        style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{props.homeSubtitle}</p>

                                                  </>
                                          :
                                          <>
                                            <h1
                                                className={'font-montserrat hidden-xs hidden-sm hidden-md\ ' + jumbotronTextColor}
                                                style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{props.motto}</h1>
                                            <h2
                                                className={'font-montserrat visible-md\ ' + jumbotronTextColor}
                                                style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{props.motto}</h2>
                                            <p
                                                className={'h3 font-montserrat hidden\ ' + jumbotronTextColor}
                                                style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{props.motto}</p>
                                            <h4 className={'font-montserrat visible-xs visible-sm\ ' + jumbotronTextColor}
                                                style={{textShadow: props.sliderTitleShadow ? '1px 1px 5px #333' : 'none'}}>{props.motto}</h4>
                                          </>
                                    }

                                  </>
                                  : ''
                            }
                          </div>
                        </div>
                  }
                </div>
              </div>
            </div>
            {
              props.sliderSearchBar == true && (isDesktop || isTablet) ?
                  <BookingSearchBar
                      isManager={props.isManager}
                      sliderSearchBar={true}
                      designMode={props.designMode}
                      searchbarThemeColor={props.hasOwnProperty('searchbarThemeColor') ? props.searchbarThemeColor : null}
                      rgbaDesignColor={props.rgbaDesignColor}
                      searchbarRadius={props.searchbarRadius}
                      searchbarShadow={props.searchbarShadow}
                      bookingSearchbarWithBedrooms={props.bookingSearchbarWithBedrooms}
                      isHomePage={true}
                  />
                  : ''
            }
          </div>
        </div>
      </div>
  )
}
