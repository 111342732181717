import * as React from 'react';

import GalleryItem from '../gallery/GalleryItem';

const ModalPropertyImages = ( props ) => {
  let skip = 0;

  return (
    <div className="modal-body">
      <div className="container">
        <div className="row w-100 no-margin">
          {
            props.all_images.map( ( image ) => {

              if ( skip == 0 || skip == 3 ) {
                skip = skip == 3 ? 0 : skip+1;
                return (
                  <GalleryItem
                    fancybox={'album'}
                    key={image.image_id != null ? image.image_id.toString() : image.image}
                    galleryClassName={'col-12 col-sm-6 padding-5\ '}
                    arrClassName={'ar-2-1'}
                    image={image.image}
                    imageThumb={image.image_thumb !=undefined ? image.image_thumb : image.image}/>
                )
              }
              skip +=1;
              return (
                <GalleryItem
                  fancybox={'album'}
                  key={image.image_id != null ? image.image_id.toString() : image.image}
                  galleryClassName={'col-6 col-sm-3 padding-5\ '}
                  arrClassName={'ar-1-1'}
                  image={image.image}
                  imageThumb={image.image_thumb !=undefined ? image.image_thumb : image.image}/>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
export default ModalPropertyImages;
