class SwiperUtils {
  private options;
  private mode = 'white';
  private swiperPagination = document.querySelector( '.swiper-pagination-horizontal' );
  private swiperPaginationBullets = document.querySelectorAll( '.swiper-pagination-bullet' );
  constructor ( options ) {
    this.options = options;
  }

  swiperOptions () {
    if ( this.options.mode ) {
      this.mode = this.options.mode;
    }
    const bg_class = 'bg-' + this.mode;

    this.swiperPaginationBullets.forEach( ( element ) => {
      element.classList.add( bg_class );
    });

    window.addEventListener( 'resize', () => {
      this.swiperPaginationBullets = document.querySelectorAll( '.swiper-pagination-bullet' )
      this.swiperPaginationBullets.forEach( ( element ) => {
        if ( !element.classList.contains( bg_class ) ) {
          element.classList.add( bg_class );
        }
      });
    });
    if ( this.options.pagination ) {
      this.swiperPagination.classList.remove( 'visible-xs' );
      this.swiperPagination.classList.remove( 'visible-sm' );
    } else {
      this.swiperPagination.classList.remove( 'visible-xs' );
      this.swiperPagination.classList.remove( 'visible-sm' );
    }

  }
}

export default SwiperUtils;
