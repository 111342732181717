import NoSsr from '@mui/material/NoSsr';
import Image from 'next/image';

interface GalleryItemProps {
  index?: number
  galleryClassName?: string
  fancybox?: string
  className?: string
  arrClassName?: string
  image: string
  imageThumb: string
  image_id?: number
  imageSize?: string
  enableHoverZoom?: boolean
  imageQuality?: number,
  wrapImage?: boolean
}

export default function GalleryItem ({index = null, galleryClassName, fancybox, className, arrClassName, image, imageThumb, image_id, imageSize = '35vw', enableHoverZoom = true, imageQuality = 35, wrapImage = true} : GalleryItemProps ) {

  let galleryClassNameLocal = '';
  let arrClassNameLocal = '';
  if ( index != null ) {
    switch ( index ) {
    case 0:
      galleryClassNameLocal = 'col-sm-6 col-12  '
      arrClassNameLocal = 'ar-2-1 '
      break;
    case 1:
    case 2:
    case 3:
    case 4:
      galleryClassNameLocal = 'col-sm-3 col-6 '
      arrClassNameLocal = 'ar-1-1'
      break;
    case 5:
      galleryClassNameLocal = 'col-sm-6 col-12 '
      arrClassNameLocal = 'ar-2-1 '
      break;
    }
  }

  if ( galleryClassName != undefined ) {
    galleryClassNameLocal += galleryClassName;
  } if ( arrClassName != undefined ) {
    arrClassNameLocal += arrClassName;
  } if ( className != undefined ) {
    galleryClassNameLocal += className + '\ ';
  }

  galleryClassNameLocal += ' no-padding';
  if ( enableHoverZoom ) {
    galleryClassNameLocal += ' hover-zoom';
  }

  const myLoader = ({ src }) => {
    return `${src}?q=55`
  }

  return (
      wrapImage ?
        <>
          <div
            data-fancybox={fancybox}
            data-src={image}
            data-thumb={imageThumb}
            className={galleryClassNameLocal}>
            <div className={arrClassNameLocal}>
              <div data-pages-bg-image="">
                <NoSsr>
                  <Image
                    // loading={ key == 0 ? 'eager' : 'lazy'}
                    loader={myLoader}
                    layout={'fill'}
                    sizes={imageSize}
                    quality={imageQuality}
                    src={imageThumb}
                    alt={'Slide-' + (index != undefined ? index : image_id)}
                    placeholder={'blur'}
                    blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                    objectFit={'cover'}
                  />
                </NoSsr>
              </div>
            </div>
          </div>
        </>
          :
          <>
            <div
                data-fancybox={fancybox}
                data-src={image}
                data-thumb={imageThumb}
                className={galleryClassName}>
              <div className={arrClassName}>
                <div data-pages-bg-image="">
                  <NoSsr>
                    <Image
                        // loading={ key == 0 ? 'eager' : 'lazy'}
                        loader={myLoader}
                        layout={'fill'}
                        sizes={imageSize}
                        quality={imageQuality}
                        src={imageThumb}
                        alt={'Slide-' + (index != undefined ? index : image_id)}
                        placeholder={'blur'}
                        blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                        objectFit={'cover'}
                    />
                  </NoSsr>
                </div>
                </div>
              </div>
          </>
  )
}
